<template>
  <div id="pie"></div>
</template>
<script>
import * as echarts from "echarts";
import { salesChartByCategory } from "../service/api/home";
export default {
  name: "Pie",
  data() {
    return {
      chartDom: null,
      myChart: null,
      options: {},
      defaultOptions: {
        title: {
          text: "暂无数据",
          x: "center",
          y: "center",
          textStyle: {
            color: "#fff",
            fontSize: 14,
          },
        },
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b} : {c} ({d}%)",
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "0%",
          top: 100,
          containLabel: true,
        },
        toolbox: {
          show: true,
          iconStyle: { color: "#000" },
          feature: {
            mark: { show: true },
            dataView: { show: true, readOnly: false },
            restore: { show: true },
          },
        },
      },
    };
  },
  created() {},
  mounted() {
    this.chartDom = document.getElementById("pie");
    this.myChart = echarts.init(this.chartDom, null, {
      renderer: "svg",
    });
    this.initEchart();
    window.onresize = function () {
      this.myChart.resize();
    };
    const chart = this.myChart;
    this.myChart &&
      this.myChart.on("showTip", function (event) {
        setTimeout(function () {
          chart.dispatchAction({ type: "hideTip" });
        }, 1000); // 1000毫秒后隐藏tooltip
      });
  },
  methods: {
    initEchart(params = {}) {
      // 后端返回示例
      salesChartByCategory(params)
        .then((res) => {
          console.log(res);
          const data = res.data;
          if (data.length <= 0) {
            this.myChart.clear();
            this.myChart.setOption(this.defaultOptions);
            return;
          }
          const legendData = data.map((el) => {
            return el.value;
          });
          const series = [
            {
              name: "类目",
              type: "pie",
              radius: [10, 80],
              center: ["46%", "50%"],
              roseType: "area",
              itemStyle: {
                borderRadius: 5,
              },
              label: {
                formatter: "{name|{b}}\n{time|{c}}",
                minMargin: 5,
                edgeDistance: 5,
                lineHeight: 15,
                rich: {
                  time: {
                    fontSize: 10,
                    color: "#fff",
                  },
                },
              },
              labelLine: {
                normal: {
                  length: 1, //第一条线
                  length2: 1, //第二条线
                  lineStyle: {
                    width: 1, // 线条的宽度
                    color: "#efefef", //线的颜色设置， 如没有设置颜色则线条的颜色跟随饼状图的颜色
                  },
                },
              },
              data: data,
            },
          ];
          const legend = {
            left: "center",
            top: "bottom",
            data: legendData,
          };
          this.option = Object.assign({}, this.defaultOptions, {
            legend,
            series,
            title: {
              show: false,
              text: "",
              left: "center",
              top: "center",
            },
          });
          console.log(111111, this.options);
          this.myChart.clear();
          this.option && this.myChart.setOption(this.option);
        })
        .catch(() => {
          this.myChart.clear();
          this.myChart.setOption(this.defaultOptions);
        });
      // const data = [
      //   { value: 40, name: "rose 1" },
      //   { value: 33, name: "rose 2" },
      //   { value: 28, name: "rose 3" },
      //   { value: 22, name: "rose 4" },
      //   { value: 20, name: "rose 5" },
      //   { value: 15, name: "rose 6" },
      //   { value: 12, name: "rose 7" },
      //   { value: 10, name: "rose 8" },
      // ];
    },
    updateChartData(params) {
      this.initEchart(params);
    },
  },
};
</script>
<style lang="less" scoped>
#pie {
  width: 100vw;
  height: 30vh;
}
</style>
