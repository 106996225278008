<template>
  <div id="lineEcharts"></div>
</template>
<script>
import * as echarts from "echarts";
import { salesChart } from "../service/api/home";
export default {
  name: "LinEcharts",
  data() {
    const generateTimeArray = () => {
      const timeArray = [];
      for (let hour = 0; hour < 24; hour++) {
        for (let minute = 0; minute < 60; minute += 60) {
          // 每15分钟一个时间点
          const hourStr = String(hour).padStart(2, "0");
          const minuteStr = String(minute).padStart(2, "0");
          timeArray.push(`${hourStr}:${minuteStr}`);
        }
      }
      return timeArray;
    };
    return {
      chartDom: null,
      myChart: null,
      options: {},
      defaultOptions: {
        title: {
          show: true,
          text: "暂无数据",
          left: "center",
          top: "center",
        },
        tooltip: {
          trigger: "axis",
          confine: true,
          alwaysShowContent: false,
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "2%",
          top: 80,
          containLabel: true,
        },
        toolbox: {
          show: true,
          iconStyle: { color: "#000" },
          feature: {
            mark: { show: true },
            dataView: { show: true },
            restore: { show: true },
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: generateTimeArray(),
        },
        yAxis: {
          type: "value",
        },
        legend: {
          top: 30,
          textStyle: { color: "#fff" },
          formatter: (name) => {
            if (!name) return "";
            if (name.length > 5) {
              name = name.slice(0, 5) + "..."; // 精华1
            }
            return name;
          },
          tooltip: {
            // 精华2
            show: true,
          },
          data: [],
        },
        series: [
          {
            name: "",
            type: "line",
            stack: "Total",
            data: [],
          },
        ],
      },
    };
  },
  created() {},
  mounted() {
    this.chartDom = document.getElementById("lineEcharts");
    this.myChart = echarts.init(this.chartDom, null, {
      renderer: "svg",
    });
    this.initEchart();
    window.onresize = function () {
      this.myChart.resize();
    };
    const chart = this.myChart;
    this.myChart &&
      this.myChart.on("showTip", function (event) {
        setTimeout(function () {
          chart.dispatchAction({ type: "hideTip" });
        }, 1000); // 1000毫秒后隐藏tooltip
      });
  },
  methods: {
    updateChartData(params) {
      this.initEchart(params);
    },
    initEchart(params = {}) {
      // 后端返回示例
      salesChart(params)
        .then((res) => {
          console.log(res);
          const data = res.data;
          if (data.length <= 0) {
            this.myChart.clear();
            this.myChart.setOption(this.defaultOptions);
            return;
          }
          const legendData = data.map((el) => {
            return el.name;
          });
          const series = data.map((el) => {
            return {
              name: el.name,
              type: "line",
              stack: "Total",
              data: el.data,
            };
          });
          console.log(series, "serris");
          const legend = {
            top: 30,
            textStyle: { color: "#fff" },
            data: legendData,
            formatter: (name) => {
              if (!name) return "";
              if (name.length > 5) {
                name = name.slice(0, 5) + "...";
              }
              return name;
            },
            tooltip: {
              show: true,
            },
          };
          this.option = Object.assign({}, this.defaultOptions, {
            legend,
            series,
            title: {
              show: false,
              text: "",
              left: "center",
              top: "center",
            },
          });
          console.log(22222222222222, this.options);
          this.myChart.clear();

          this.option && this.myChart.setOption(this.option);
        })
        .catch(() => {
          this.myChart.clear();
          this.myChart.setOption(this.defaultOptions);
        });
    },
  },
};
</script>
<style lang="less" scoped>
#lineEcharts {
  width: 100vw;
  height: 250px;
}
</style>
