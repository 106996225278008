import requests from '../request'
// 获取当天销售总额：/salesTotal
// 按分类获取销售额：/salesChartByCategory
// /chart/product/salesChart
// 
export const salesChart = (params) => {
    return requests({
        url: '/chart/product/salesChart',
        method: 'get',
        params
    })
}
export const salesChartByCategory = (params) => {
    return requests({
        url: '/chart/product/salesChartByCategory',
        method: 'get',
        params
    })
}
export const salesTotal = (params) => {
    return requests({
        url: '/chart/product/salesTotal',
        method: 'get',
        params
    })
}