<template>
  <div id="home">
    <div class="wrapper">
      <header>
        <div class="header_title">数据总览</div>
      </header>
      <main class="main" style="width: 100%">
        <div>
          <van-popup
            close-on-click-overlay
            v-model:show="showTimeSelect"
            round
            position="bottom"
          >
            <van-datetime-picker
              type="date"
              :min-date="minDate"
              :max-date="maxDate"
              title="选择年月日"
              @confirm="onTimeSelectConfirm"
              @cancel="onCancelTimeSelect"
            ></van-datetime-picker>
          </van-popup>
          <van-cell
            title="选择日期"
            :value="params.queryDate"
            @click="beginSelectTime('queryDate')"
          >
          </van-cell>
          <ProductSelect
            :visible="selectProductVisible"
            @confirm="onSelectProductConfirm"
          ></ProductSelect>
          <!-- <van-cell
            title="选择开始日期"
            :value="params.startDate"
            @click="beginSelectTime('startDate')"
          />
          <van-cell
            title="选择结束日期"
            :value="params.endDate"
            @click="beginSelectTime('endDate')"
          /> -->
          <van-cell
            title="选择商品"
            :value="params.productName"
            @click="selectProductVisible = true"
          >
            {{ params.productName }}
            <van-icon name="close" color="#fff" @click="clearProduct($event)" />
          </van-cell>
          <van-button
            type="primary"
            size="large"
            class="searchBtn"
            @click="toSearch"
            loading-text="正在加载中..."
            :loading="loading"
            >查询</van-button
          >
        </div>
        <div class="total">
          <div class="">销售总金额</div>
          <div class="saleTotal">
            <span>{{ saleAmount }}</span>
          </div>
        </div>
        <div class="main_center">
          <h2 class="timeselect">
            <div class="content-title-box" style="padding-left: 0px">
              类目销售额
            </div>
          </h2>
          <Pie ref="pie"></Pie>
        </div>
        <div class="main_center">
          <h2 class="timeselect">
            <div class="content-title-box" style="padding-left: 0px">
              商品TOP5销量
            </div>
          </h2>
          <LineEcharts ref="lineEcharts"></LineEcharts>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import LineEcharts from "../../components/lineEcharts.vue";
import Pie from "../../components/Pie.vue";
import DateTimePicker from "../../components/dateTimePicker.vue";
import ProductSelect from "../../components/productSelect.vue";
import { salesTotal } from "../../service/api/home";
import { formatDate } from "../../utils";

export default {
  name: "Home",
  data() {
    return {
      loading: false,
      key: "",
      saleAmount: 0,
      showTimeSelect: false,
      minDate: new Date(2024, 10, 1),
      maxDate: new Date(),
      selectProductVisible: false,
      params: {
        productName: "",
        queryDate: "",
        productId: "",
      },
    };
  },
  created() {
    this.params.queryDate = formatDate(new Date());
    this.getTotal();
  },

  mounted() {},
  components: {
    LineEcharts,
    Pie,
    DateTimePicker,
    ProductSelect,
  },
  methods: {
    clearProduct(e) {
      console.log("click");
      e.preventDefault();
      e.stopPropagation();

      this.params.productName = "";
      this.params.productId = "";
    },
    getTotal() {
      this.loading = true;
      salesTotal(this.params)
        .then((res) => {
          this.saleAmount = res.data;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    toSearch() {
      this.getTotal();
      this.$refs &&
        this.$refs.pie &&
        this.$refs.pie.updateChartData(this.params);
      this.$refs &&
        this.$refs.lineEcharts &&
        this.$refs.lineEcharts.updateChartData(this.params);
    },
    onSelectProductConfirm(row) {
      if (this.selectProductVisible) {
        this.selectProductVisible = false;
      }
      if (row.length > 0) {
        console.log(this.params, row);
        this.params.productId = row[0] ? row[0].id : "";
        this.params.productName = row[0] ? row[0].name : "";
      }
    },
    beginSelectTime(key) {
      this.key = key;
      this.showTimeSelect = true;
    },
    onTimeSelectConfirm(value) {
      this.params.queryDate = formatDate(value);
      console.log(this.params.queryDate);
      this.showTimeSelect = false;
    },
    onCancelTimeSelect() {
      this.showTimeSelect = false;
    },
  },
};
</script>

<style lang="less" scoped>
.van-cell {
  background-color: transparent;
  padding: 3px 16px;
  ::v-deep .van-cell__title {
    color: #f7f7f7;
    font-size: 12px;
  }
  ::v-deep .van-cell__value {
    color: #f7f7f7;
    font-size: 12px;
  }
}
.searchBtn {
  margin: 10px;
  width: calc(100vw - 20px);
  border-radius: 20px;
  background: #00294c;
  border: none;
  color: #fff;
}
#home {
  width: 100%;
  background: linear-gradient(
    270deg,
    rgba(26, 199, 255, 0) 0%,
    rgba(26, 199, 255, 0.06) 51%,
    rgba(26, 199, 255, 0) 100%
  );
}
.total {
  padding: 20px;
  font-size: 22px;
  font-weight: bold;
  background: #00294c;
  margin: 10px;
  border-radius: 5px;
  box-shadow: 2px 2px 3px 4px #000a2d;
  color: rgba(0, 247, 254, 1);
  text-align: center;
}
.saleTotal {
  padding: 10px 0 0 0;
  font-size: 24px;
  font-weight: 800;
  color: #fff;
}
.wrapper {
  width: 100%;
  // background: url("../../assets/images/bg.png");
  background-size: cover;
  display: flex;
  flex-direction: column;
  background-color: #000b2d;
  color: #fff;
  padding-bottom: 80px;
  box-sizing: border-box;
}
header {
  width: 100%;
  height: 46px;
  // background: url("../../assets/images/titleheader.png") no-repeat;
  background-size: 100% 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;

  .header_title {
    height: 64px;
    font-size: 20px;
    margin-top: 0;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    font-weight: bold;
    color: #ffffff;
    line-height: 64px;
    letter-spacing: 1px;
    background: linear-gradient(360deg, #46b1ff 0%, #136fb1 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
.main {
  flex: 1;
  width: 100%;
  width: calc(100vw - 64px);
  margin: 0 auto;
  // overflow: hidden;
}
.main_left {
  position: relative;
  width: 100%;
  height: 320px;
}
.main_center {
  position: relative;
  width: 100%;
}
.main_bottom {
  position: relative;
  width: 100%;
  height: 220px;
}
.center-one {
  height: 90%;
  width: 100%;
  margin: 0 auto;
}
.bottom-one {
  height: 100%;
  width: 100%;
  margin: 0 auto;
}
.sjk-speed {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  .connect_number {
    display: block;
    width: 45%;
    height: 100%;
  }
  .speed {
    width: 30%;
    height: 100%;
  }
}
.server_content_right—top {
  width: 100%;
  height: 100%;
  // margin-top: -24px;
  // padding-top: 24px;
}
.speed_item {
  flex: 1;
  // background: url("../../assets/images/msg_con.png") no-repeat;
  background-size: 84%;
  background-position: center 20%;

  .speed_title {
    text-align: center;
    margin: 10px auto 0;
    color: rgb(70, 197, 165);
    font-size: 12px;
  }

  .speed_msg {
    color: wheat;
    margin: 0 auto;
    margin-top: 17px;
    text-align: center;
    font-size: 14px;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    .item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      height: 20px;
      line-height: 20px;
      font-size: 14px;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: 700;
      color: #1ac7ff;
      letter-spacing: 2px;
      span {
        line-height: 30px;
      }
      .imgBox {
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin-top: 5px;
        .num {
          width: 35%;
          height: 100%;
          line-height: 100%;
          text-align: center;
          background-repeat: no-repeat;
          background-size: 100%;
          font-size: 14px;
          font-family: MTC;
          color: #f0f1e7;
        }
      }
    }
  }

  .speed_unit {
    font-size: 10px;
    position: absolute;
    top: 3px;
    color: white;
  }
}
.sjk {
  width: 100%;
  height: 195px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  .sjk-first {
    height: 100%;
    width: 100%;
  }
  .speed {
    border-left: 0.1px solid #2471a242;
  }
}
.ld-left {
  width: 100%;
  height: 100%;
  flex: 1;
  // background: url("../../assets/images/pieChart@2x.png") no-repeat;

  background-position-x: center;
  background-position-y: 200px;
  background-size: 150px 68px;
  .left-one {
    height: 30%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    .item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      height: 20px;
      line-height: 20px;
      font-size: 14px;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: 700;
      color: #1ac7ff;
      letter-spacing: 2px;
      .imgBox {
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin-top: 5px;
        .num {
          width: 35%;
          margin-left: 2px;
          height: 100%;
          line-height: 100%;
          text-align: center;
          background-repeat: no-repeat;
          background-size: 100%;
          font-size: 24px;
          font-family: MTC;
          color: #e4c51a;
          padding: 5px;
        }
        // .numBG1 {
        //   background: url("../../assets/images/数字bg3@2x.png") no-repeat 46%
        //     47%;
        // }
        // .numBG2 {
        //   background: url("../../assets/images/数字bg5@2x.png") no-repeat 46%
        //     47%;
        // }
        // .numBG3 {
        //   background: url("../../assets/images/数字bg6@2x.png") no-repeat 46%
        //     47%;
        // }
      }
    }
  }
}
.content-title-box {
  font-size: 16px;
  font-weight: bold;
  color: rgba(0, 247, 254, 1);
  line-height: 21px;
  letter-spacing: 1px;
  margin-top: 4px;
  margin-left: 28px;
  position: relative;

  &::after {
    display: block;
    content: "";
    width: 4px;
    height: 16px;
    background: #00f7fe;
    border-radius: 2px;
    position: absolute;
    left: -15px;
    top: 2.5px;
  }
}
.timeselect {
  width: 100%;
  height: 24px;
  margin: 0px;
  line-height: 24px;
  color: #1ac7ff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  div {
    width: 41%;
    display: block;
  }
}
.main_right_top_content {
  width: 100%;
  height: 100%;
  padding-top: 1px;
}
.left-top-img-box {
  position: absolute;
  left: -14px;
  top: -14px;
  margin: 0;
  padding: 0;
  width: 35px;
  height: 35px;
}
.ld-all {
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: column;
}
.left-two {
  height: 72%;
  width: 100%;
}
.box-img img {
  width: 100%;
  height: 100%;
}

.left-bottom-img-box {
  position: absolute;
  left: -14px;
  bottom: -14px;
  margin: 0;
  padding: 0;
  width: 35px;
  height: 35px;
}

.right-top-img-box {
  position: absolute;
  top: -14px;
  right: -14px;
  margin: 0;
  padding: 0;
  width: 35px;
  height: 35px;
}

.right-bottom-img-box {
  position: absolute;
  right: -14px;
  bottom: -14px;
  margin: 0;
  padding: 0;
  width: 35px;
  height: 35px;
}
</style>
