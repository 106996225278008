import { render, staticRenderFns } from "./Pie.vue?vue&type=template&id=40138630&scoped=true"
import script from "./Pie.vue?vue&type=script&lang=js"
export * from "./Pie.vue?vue&type=script&lang=js"
import style0 from "./Pie.vue?vue&type=style&index=0&id=40138630&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40138630",
  null
  
)

export default component.exports